// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
/* eslint-disable */

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: process.env.REACT_APP_STAGE === "prod" ? "us-east-1_iNNkLQSh7" : "us-east-1_NbVCLJl3A",
  aws_user_pools_web_client_id:
    process.env.REACT_APP_STAGE === "prod" ? "5imv11og0gr4hsppkdduq0k7vg" : "6606ooq2eptad3gh6odd6m8oep",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
